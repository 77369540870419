<!-- <stbui-loading *ngIf="showLoading"></stbui-loading> -->
<mat-toolbar   class="mat-elevation-z4" style="background-color: #1b334d;">  
	<button mat-icon-button (click)="sidenav.toggle();drawer.toggle();" *ngIf="matDrawerShow">
        <i style="color: white;" class="material-icons app-toolbar-menu">menu </i>
    </button> 
    <button mat-icon-button (click)="sidenav.toggle();" *ngIf="!matDrawerShow">
        <i class="material-icons app-toolbar-menu">menu </i>
    </button>

    <span class="spacer" style="color: #1b334d;"></span>

   <!-- <button  mat-icon-button (click)="searchOpen = !searchOpen" fxHide="true" [fxHide.gt-xs]="false">
        <i class="material-icons">search</i>
    </button>
    <cdk-search-bar [open]="searchOpen"></cdk-search-bar> -->

    <cdk-fullscreen style="color: white;"></cdk-fullscreen>

   <!-- <cdk-toolbar-notification [notifications]="toolbarHelpers?.notifications"></cdk-toolbar-notification>-->

    <cdk-user-menu id="userMenu" style="padding: 30px 0px 0px 00px; width: 250px; color:#ffffff; font-size: 15px;" [currentUser]="toolbarHelpers?.currentUser"></cdk-user-menu>
    
    <!-- <button mat-icon-button (click)="sidebar.toggle();">
        <i class="material-icons app-toolbar-menu">menu </i>
    </button>-->
    
</mat-toolbar>