import { Component, OnInit, Input } from '@angular/core';
import { menus } from './menu-element';
import { menusUser } from './menu-user';
import { AppCookieService } from '../../cookie/CookieService';
@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Input() iconOnly: boolean = false;

  public menus;

  constructor(private appCokieService: AppCookieService) { }

  ngOnInit() {
    if (this.appCokieService.get("userType") == "0") {
      this.menus = menus;
    } else {
      this.menus =menusUser;
    }
  }
}
