<div  class="round-progressbar mat-elevation-z4 "  fxLayout="column" [style.background]="boxcolor">
    <h1 class="mat-headline header">
            {{title}}
    </h1>
    <div  fxLayout="row" >

        <div fxFlex="10">
        </div>
        <div fxFlex="80" >
              <div class="progress-wrapper">
                    <div class="current mat-headline " [ngStyle]="getOverlayStyle()">{{ current }}/{{ max }}</div>
                    <round-progress  [current]="current"
                    [max]="max"
                    [color]="color"
                    [background]="background"
                    [radius]="125"
                    [stroke]="30"
                    [semicircle]="semicircle"
                    [rounded]="rounded"
                    [clockwise]="clockwise"
                    [responsive]="responsive"
                    [duration]="800"
                    [animation]="animation"
                    [animationDelay]="1"
                     ></round-progress>
              </div>
           
        </div>
        <div  fxFlex="10"></div>
    </div>

</div>