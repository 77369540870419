import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppCookieService } from '../cookie/CookieService';
import { AuthModule } from '../auth/auth.module';

const routes: Routes = [
    { path: 'auth', loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule) },
    { path: 'register', loadChildren: () => import('../register/register.module').then(m => m.RegisterModule) },
    { path: 'login', loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule) },
    { path: 'forgotpassword', loadChildren: () => import('../pages/forgotpassword/forgot-password.module').then(m => m.ForgotPasswordModule) },
    // {path: 'editor', loadChildren: () => import('../editor/editor.module').then(m => m.EditorModule)},

    { path: '**', redirectTo: 'auth/tables/customer' },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class LazyLoadModule {
    constructor(private appcokieservice: AppCookieService, public router: Router) {
        this.redirectpage()
    }

    redirectpage() {
        if (this.appcokieservice.get("token") != null) {
            if (this.appcokieservice.get("userType") == "0") {
                this.router.navigate(['auth/tables/user']);
            } else
                this.router.navigate(['auth/tables/customer-machine']);
        } else  {
            if(this.appcokieservice.get("forgotPassword")== null){
                this.router.navigate(['/login']);
            }else{
                this.router.navigate(['/forgotpassword']);
            }
        }
    }
}
