import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { AppCookieService } from '../../cookie/CookieService';
import { Router } from '@angular/router';
@Component({
	selector: 'cdk-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
	isOpen: boolean = false;

	//currentUser = null;
	Hari;


	@Input() currentUser = null;
	@HostListener('document:click', ['$event', '$event.target'])
	onClick(event: MouseEvent, targetElement: HTMLElement) {
		if (!targetElement) {
			return;
		}
		if (targetElement.innerHTML == "LogOut") {
			this.logOut();
		} else if (targetElement.innerHTML == "Profile") {
			this.redirectProfile();
		} else if (targetElement.innerHTML == "Change Password") {
			this.redirectChangePassword();
		}
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.isOpen = false;
		}
	}


	constructor(public router: Router, private elementRef: ElementRef, private appcokieservice: AppCookieService) { }


	ngOnInit() {
	}
	redirectProfile() {
		this.router.navigate(['auth/pages/profile']);
	}
	redirectChangePassword() {
		this.router.navigate(['auth/pages/changePassword']);
	}

	logOut() {
		this.appcokieservice.remove("token");
		this.appcokieservice.remove("userName");
		this.appcokieservice.remove("forgotPassword");
		this.router.navigate(['/login']);
	}

}
