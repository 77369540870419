import { Component, OnInit, Input, Injectable } from '@angular/core';
import { ToolbarHelpers } from './toolbar.helpers';
import { AppCookieService } from '../../cookie/CookieService';
@Component({
	selector: 'cdk-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
@Injectable({
    providedIn: 'root'
  })
export class ToolbarComponent implements OnInit {

	@Input() sidenav;
	@Input() sidebar;
	@Input() drawer;
	@Input() matDrawerShow;

	searchOpen: boolean = false;
	public toolbarHelpers = ToolbarHelpers;
	constructor(private appCokieService: AppCookieService) { }

	ngOnInit() {
		this.toolbarHelpers.currentUser.currentUserName=this.appCokieService.get("userName")
	}
}
