export const menus = [
    {
        'name': 'Users',
        'icon': 'accessibility',
        'open': false,
        'chip': false,
        'link': '',
        'sub': [
            {
                'name': 'User List',
                'icon': 'list',
                'link': 'tables/users',
                'open': false,
            },
            {
                'name': 'Add User',
                'icon': 'filter_list',
                'link': 'pages/user',
                'open': false,
            }
        ]

    },
    {
        'name': 'Machines',
        'icon': 'list',
        'open': false,
        'chip': false,
        'link': '',
        'sub': [
            {
                'name': 'Machine List',
                'icon': 'list',
                'link': 'tables/machine',
                'open': false,
            },
            {
                'name': 'Add Machine',
                'icon': 'filter_list',
                'link': 'pages/machine',
                'open': false,
            }
        ]

    }
];
