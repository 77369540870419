import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { AppCookieService } from '../cookie/CookieService';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
​
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
​
	constructor(
		private appCokieService: AppCookieService,
		private router: Router
	) {}
​
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!!this.appCokieService.get("token")) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this.appCokieService.get("token")}`
				}
			});
            request = request.clone({
				setHeaders: {
					'content-type': 'application/json'
				}
			});
		}
​
		return next.handle(request)
		.pipe(
			tap(null,
			error => {
				if(error.error.message == "isAdmin"){
					this.appCokieService.set("forgotPassword","true")
				  }
				if(this.appCokieService.get("forgotPassword")== null && error.status === 401) {
					this.logOut();
					this.router.navigate(['/login']);
				}
			})
		);
	}
    logOut(){
        this.appCokieService.remove("token");
        this.appCokieService.remove("userType");
        this.appCokieService.remove("userName")
    }
}